import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import api from "../../utils/api";
import { FaUser } from "react-icons/fa";
import { useQueryClient } from "@tanstack/react-query";

const GeneralInformationTab = ({ profile }) => {
    const [profileImage, setProfileImage] = useState(profile.profile_image || null);
    const queryClient = useQueryClient();

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        phone: Yup.string(),
        location: Yup.string().required("Location is required"),
        bio: Yup.string()
    });

    const handleImageUpload = async (event, setFieldValue) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("profile_image", file);

            try {
                const response = await api.post("/api/profile/upload-image", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                const newImageUrl = response.data.image_url;
                setProfileImage(newImageUrl);
                setFieldValue("profile_image", newImageUrl);

                // Invalidate the profile query to trigger a refresh
                queryClient.invalidateQueries(["profile"]);
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await api.put("/api/profile/general", values);
            // Show success message
        } catch (error) {
            console.error("Error updating profile:", error);
            // Show error message
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={{
                name: profile.name || "",
                email: profile.email || "",
                phone: profile.phone || "",
                location: profile.location || "",
                bio: profile.bio || "",
                profile_image: profile.profile_image || ""
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, setFieldValue }) => (
                <Form className="profile-editor-form">
                    <div className="profile-image-section">
                        <div className="profile-image-container">
                            {profileImage ? (
                                <img
                                    src={profileImage}
                                    alt="Profile"
                                    className="profile-image"
                                />
                            ) : (
                                <div className="profile-image-placeholder">
                                    <FaUser size={50} color="#666" />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="profile-image-controls">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, setFieldValue)}
                            className="profile-image-input"
                            id="profile-image-input"
                        />
                        <label htmlFor="profile-image-input" className="profile-image-label">
                            Change Photo
                        </label>
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <Field
                            name="name"
                            className={`form-input ${errors.name && touched.name ? "error" : ""}`}
                        />
                        {errors.name && touched.name && (
                            <div className="error-message">{errors.name}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                            name="email"
                            type="email"
                            className={`form-input ${errors.email && touched.email ? "error" : ""}`}
                        />
                        {errors.email && touched.email && (
                            <div className="error-message">{errors.email}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <Field
                            name="phone"
                            className={`form-input ${errors.phone && touched.phone ? "error" : ""}`}
                        />
                        {errors.phone && touched.phone && (
                            <div className="error-message">{errors.phone}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="location">Location</label>
                        <Field
                            name="location"
                            className={`form-input ${errors.location && touched.location ? "error" : ""}`}
                        />
                        {errors.location && touched.location && (
                            <div className="error-message">{errors.location}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="bio">Bio</label>
                        <Field
                            name="bio"
                            as="textarea"
                            className={`form-textarea ${errors.bio && touched.bio ? "error" : ""}`}
                            rows="4"
                        />
                        {errors.bio && touched.bio && (
                            <div className="error-message">{errors.bio}</div>
                        )}
                    </div>

                    <button type="submit" className="submit-button">
                        Save Changes
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default GeneralInformationTab; 