import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";
import api from "../utils/api";
import "../App.css";
import Select from "react-select";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const CVFormSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    location: Yup.string().required('Location is required'),
    company: Yup.string().required('Company is required'),
    phone: Yup.string().required('Phone is required'),
    introduction: Yup.string().required('Introduction is required'),
    skills: Yup.array()
        .min(3, 'Please select at least 3 skills')
        .max(6, 'Please select no more than 6 skills'),
    industries: Yup.array()
        .min(2, 'Please select at least 2 industries')
        .max(6, 'Please select no more than 6 industries'),
    experiences: Yup.array()
        .min(3, 'Please select at least 3 experiences')
        .max(5, 'Please select no more than 5 experiences'),
});

const UserProfile = () => {
    const { employeeId } = useParams({ from: '/user-profile/$employeeId' });
    const { data: profile, isLoading, error } = useQuery({
        queryKey: ["user-profile", employeeId],
        queryFn: async () => {
            const response = await api.get(`/api/user-profile/${employeeId}`);
            return response.data;
        },
    });

    const [showForm, setShowForm] = useState(false);
    const [showImageNotification, setShowImageNotification] = useState(false);

    if (isLoading) return <div className="App">Loading...</div>;
    if (error) return <div className="App">Error: {error.message}</div>;
    if (!profile) return <div className="App">Profile not found</div>;

    const renderAvailabilityDots = (availability) => {
        if (!availability || !availability.length) return null;

        return (
            <div className="availability-container">
                {availability.map((weekData, index) => {
                    let colorClass = '';
                    if (weekData.available_hours < 10) {
                        colorClass = 'red-dot';
                    } else if (weekData.available_hours >= 10 && weekData.available_hours <= 30) {
                        colorClass = 'yellow-dot';
                    } else {
                        colorClass = 'green-dot';
                    }
                    return (
                        <div key={index} className="availability-dot-container">
                            <span className={`availability-dot ${colorClass}`}>
                                W{weekData.week_number}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            // Map selected experience headers to full experience objects
            const formattedExperiences = values.experiences.map(selectedHeader => {
                const fullExperience = profile.experiences.find(exp => exp.header === selectedHeader);
                return {
                    header: selectedHeader,
                    description: fullExperience ? fullExperience.description : ''
                };
            });

            // Map selected skill names to full skill objects with levels
            const formattedSkills = values.skills.map(selectedSkillName => {
                const fullSkill = profile.skills.find(skill => skill.name === selectedSkillName);
                return {
                    name: selectedSkillName,
                    level: fullSkill ? fullSkill.level : 0
                };
            });

            // log values
            console.log(values);
            const response = await api.post('/api/create-cv', {
                employeeId,
                email: profile.email_address,
                name: profile.name,
                ...values,
                experiences: formattedExperiences,
                skills: formattedSkills,
                template: values.template
            });

            // Handle the CV file download
            if (response.data.success) {
                // Convert base64 to file and download
                const byteCharacters = atob(response.data.file);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });

                // Create download link
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = response.data.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);

                // Check if profile image exists using the flag from backend
                if (!response.data.has_profile_image) {
                    setShowImageNotification(true);
                    // Auto-hide notification after 10 seconds
                    setTimeout(() => setShowImageNotification(false), 10000);
                }
            }

            setSubmitting(false);
            setShowForm(false);
        } catch (error) {
            console.error('Error creating CV:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <div className="App">
            <div className="profile-container">
                <div className="profile-card">
                    <div className="profile-header">
                        <div className="profile-header-content">
                            <h2>{profile.name}</h2>
                            <p className="profile-company">{profile.company}</p>
                        </div>
                        <div className="profile-header-buttons">
                            {profile.link_to_cv_file && (
                                <a 
                                    href={profile.link_to_cv_file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="view-cv-button"
                                >
                                    View CV
                                </a>
                            )}
                            <button
                                className="create-cv-button"
                                onClick={() => setShowForm(true)}
                            >
                                Create CV
                            </button>
                        </div>
                    </div>

                    {showForm && (
                        <div className="cv-form-overlay">
                            <div className="cv-form">
                                <h3>Create CV</h3>
                                <Formik
                                    initialValues={{
                                        template: 'Eidra',
                                        role: '',
                                        location: profile.location || '',
                                        company: profile.company || '',
                                        phone: '+46000000000',
                                        introduction: '',
                                        skills: profile.skills.map(skill => skill.name),
                                        industries: profile.industries,
                                        experiences: profile.experiences.map(exp => exp.header)
                                    }}
                                    validationSchema={CVFormSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ errors, touched, setFieldValue, values }) => (
                                        <Form>
                                            <div className="form-group">
                                                <label>Template</label>
                                                <Select
                                                    name="template"
                                                    options={[{ value: 'Eidra', label: 'Eidra' }, { value: 'Curamando', label: 'Curamando' }, { value: 'Conversionista', label: 'Conversionista!' }]}
                                                    defaultValue={{ value: 'Eidra', label: 'Eidra' }}
                                                    isSearchable={false}
                                                    onChange={(option) => setFieldValue('template', option.value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Role</label>
                                                <Field
                                                    name="role"
                                                    className={`form-input ${errors.role && touched.role ? 'error' : ''}`}
                                                />
                                                {errors.role && touched.role && (
                                                    <span className="error-message">{errors.role}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>Location</label>
                                                <Field
                                                    name="location"
                                                    className={`form-input ${errors.location && touched.location ? 'error' : ''}`}
                                                />
                                                {errors.location && touched.location && (
                                                    <span className="error-message">{errors.location}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>Company</label>
                                                <Field
                                                    name="company"
                                                    className={`form-input ${errors.company && touched.company ? 'error' : ''}`}
                                                />
                                                {errors.company && touched.company && (
                                                    <span className="error-message">{errors.company}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>Phone</label>
                                                <Field
                                                    name="phone"
                                                    type="tel"
                                                    className={`form-input ${errors.phone && touched.phone ? 'error' : ''}`}
                                                />
                                                {errors.phone && touched.phone && (
                                                    <span className="error-message">{errors.phone}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>Introduction</label>
                                                <Field
                                                    as="textarea"
                                                    name="introduction"
                                                    className={`form-textarea ${errors.introduction && touched.introduction ? 'error' : ''}`}
                                                    rows="4"
                                                    placeholder="Write a brief introduction..."
                                                />
                                                {errors.introduction && touched.introduction && (
                                                    <span className="error-message">{errors.introduction}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>Experiences</label>
                                                <div className={`checkbox-group ${errors.experiences ? 'error' : ''}`}>
                                                    {profile.experiences.map((experience, index) => (
                                                        <label key={index} className="checkbox-label">
                                                            <input
                                                                type="checkbox"
                                                                checked={values.experiences.includes(experience.header)}
                                                                onChange={(e) => {
                                                                    const newExperiences = e.target.checked
                                                                        ? [...values.experiences, experience.header]
                                                                        : values.experiences.filter(exp => exp !== experience.header);
                                                                    setFieldValue('experiences', newExperiences);
                                                                }}
                                                            />
                                                            {experience.header}
                                                        </label>
                                                    ))}
                                                </div>
                                                {errors.experiences && (
                                                    <span className="error-message">{errors.experiences}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>Skills</label>
                                                <div className={`checkbox-group ${errors.skills ? 'error' : ''}`}>
                                                    {profile.skills.map((skill, index) => (
                                                        <label key={index} className="checkbox-label">
                                                            <input
                                                                type="checkbox"
                                                                checked={values.skills.includes(skill.name)}
                                                                onChange={(e) => {
                                                                    const newSkills = e.target.checked
                                                                        ? [...values.skills, skill.name]
                                                                        : values.skills.filter(s => s !== skill.name);
                                                                    setFieldValue('skills', newSkills);
                                                                }}
                                                            />
                                                            {skill.name}
                                                        </label>
                                                    ))}
                                                </div>
                                                {errors.skills && (
                                                    <span className="error-message">{errors.skills}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>Industries</label>
                                                <div className={`checkbox-group ${errors.industries ? 'error' : ''}`}>
                                                    {profile.industries.map((industry, index) => (
                                                        <label key={index} className="checkbox-label">
                                                            <input
                                                                type="checkbox"
                                                                checked={values.industries.includes(industry)}
                                                                onChange={(e) => {
                                                                    const newIndustries = e.target.checked
                                                                        ? [...values.industries, industry]
                                                                        : values.industries.filter(i => i !== industry);
                                                                    setFieldValue('industries', newIndustries);
                                                                }}
                                                            />
                                                            {industry}
                                                        </label>
                                                    ))}
                                                </div>
                                                {errors.industries && (
                                                    <span className="error-message">{errors.industries}</span>
                                                )}
                                            </div>

                                            {console.log('Validation errors:', errors)}
                                            
                                            {Object.keys(errors).length > 0 && (
                                                <div className="form-error-message" style={{
                                                    color: '#dc3545',
                                                    textAlign: 'center',
                                                    margin: '10px 0',
                                                    padding: '8px',
                                                    borderRadius: '4px',
                                                    backgroundColor: 'rgba(220, 53, 69, 0.1)',
                                                    fontWeight: 'bold'  // Added for better visibility
                                                }}>
                                                    Required fields are missing
                                                </div>
                                            )}

                                            <button type="submit" className="create-cv-submit">
                                                Create CV
                                            </button>
                                            <button
                                                type="button"
                                                className="close-form"
                                                onClick={() => setShowForm(false)}
                                            >
                                                ✕
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    )}

                    <div className="profile-section">
                        <h3>Basic Information</h3>
                        <div className="profile-info-grid">
                            <div className="info-item">
                                <strong>Track:</strong> {profile.track}
                            </div>
                            <div className="info-item">
                                <strong>Skill Track:</strong> {profile.skill_track}
                            </div>
                            <div className="info-item">
                                <strong>Seniority:</strong> {profile.seniority}
                            </div>
                            <div className="info-item">
                                <strong>Location:</strong> {profile.location}
                            </div>
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Experiences</h3>
                        <div className="experiences-grid">
                            {profile.experiences.map((experience, index) => (
                                <div key={index} className="experience-item">
                                    <h4 className="experience-header">{experience.header}</h4>
                                    {experience.description && (
                                        <p className="experience-description">{experience.description}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Skills</h3>
                        <div className="skills-grid">
                            {profile.skills.map((skill, index) => (
                                <div key={index} className="skill-item">
                                    <span className="skill-name">{skill.name}</span>
                                    <div className="skill-level">
                                        {[...Array(10)].map((_, i) => (
                                            <span
                                                key={i}
                                                className={`dot ${i < skill.level ? "filled" : "empty"}`}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Tools</h3>
                        <div className="tools-grid">
                            {profile.tools.map((tool, index) => (
                                <span key={index} className="tool-tag">
                                    {tool}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Industries</h3>
                        <div className="industries-grid">
                            {profile.industries.map((industry, index) => (
                                <span key={index} className="industry-tag">
                                    {industry}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className="profile-section">
                        <h3>Availability</h3>
                        {renderAvailabilityDots(profile.availability_per_week)}
                    </div>
                </div>
            </div>

            {/* Add notification component */}
            {showImageNotification && (
                <div className="notification-overlay">
                    <div className="notification-card">
                        <p>Your CV has been created successfully! Would you like to add a profile picture to enhance your CV?</p>
                        <div className="notification-actions">
                            <button
                                className="primary-button"
                                onClick={() => {
                                    setShowImageNotification(false);
                                    // Navigate to profile page
                                    window.location.href = '/profile';
                                }}
                            >
                                Add Profile Picture
                            </button>
                            <button
                                className="secondary-button"
                                onClick={() => setShowImageNotification(false)}
                            >
                                Maybe Later
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserProfile; 
