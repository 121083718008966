import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../utils/api";
import GeneralInformationTab from "../components/Profile/GeneralInformationTab";

const TABS = {
    GENERAL: "General Information",
    WORK: "Work and Education",
    EXPERIENCES: "Experiences",
    SKILLS: "Skills",
    INDUSTRIES: "Industries"
};

const ProfileEditor = () => {
    const [activeTab, setActiveTab] = useState(TABS.GENERAL);
    const { data: profile, isLoading, error } = useQuery({
        queryKey: ["profile"],
        queryFn: async () => {
            const [profileResponse, imageResponse] = await Promise.all([
                api.get("/api/profile"),
                api.get("/api/profile/image").catch(() => ({ data: { image_url: null } }))
            ]);

            return {
                ...profileResponse.data[0],
                profile_image: imageResponse.data.image_url
            };
        },
    });

    if (isLoading) return <div className="App">Loading...</div>;
    if (error) return <div className="App">Error: {error.message}</div>;
    if (!profile) return <div className="App">No profile data found</div>;

    const renderTabContent = () => {
        switch (activeTab) {
            case TABS.GENERAL:
                return <GeneralInformationTab profile={profile} />;
            // Other tabs will be implemented later
            default:
                return null;
        }
    };

    return (
        <div className="App">
            <div className="profile-editor-container">
                <h2>Edit Profile</h2>
                <div className="tabs-container">
                    {Object.values(TABS).map((tab) => (
                        <button
                            key={tab}
                            className={`tab-button ${activeTab === tab ? "active" : ""}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
                <div className="tab-content">
                    {renderTabContent()}
                </div>
            </div>
        </div>
    );
};

export default ProfileEditor; 