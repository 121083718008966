import App from "./pages/App"
import Layout from "./pages/Layout"
import AISearch from "./pages/AISearch"
import FAQ from "./pages/FAQ"
import LoginPage from "./pages/LoginPage"
import Profile from "./pages/ProfileEditor"
import UserProfile from "./pages/UserProfile"
import { senioritiesQueryOptions } from "./api"
import { createRoute, createRootRoute } from "@tanstack/react-router"
import CVMaker from "./pages/CVMaker"

// Root route, visible on all pages
const rootRoute = createRootRoute({
  component: Layout
})

// Here, more routes can be created

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: LoginPage,
})

const searchRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/search",
  component: App,
  // Fetch the initial data for this component (filter options)
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(senioritiesQueryOptions)
})

const aiSearchRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/ai-search",
  component: AISearch
})

const faqRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/faq",
  component: FAQ
})

const profileRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/profile",
  component: Profile
})

const userProfileRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/user-profile/$employeeId",
  component: UserProfile,
  validateSearch: (search) => ({}),
  loader: ({ params: { employeeId } }) => ({
    employeeId
  })
})

const cvMakerRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/cv-maker",
  component: CVMaker
})

export const childRoutes = [indexRoute, searchRoute, faqRoute, aiSearchRoute, profileRoute, userProfileRoute, cvMakerRoute]
export default rootRoute
