import React, { useState } from "react";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import api from "../utils/api";

const CVMaker = () => {
    const [description, setDescription] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);

    // Fetch users data
    const { data: users, isLoading } = useQuery({
        queryKey: ["users"],
        queryFn: async () => {
            const response = await api.get("/api/users");
            return response.data;
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post("/api/ai-cv-maker", {
                description,
                emails: selectedUsers.map(user => user.value)
            });

            // Download each generated CV
            response.data.files.forEach(fileData => {
                const binary = atob(fileData.file);
                const array = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    array[i] = binary.charCodeAt(i);
                }
                const blob = new Blob([array], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileData.filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error("Error generating CVs:", error);
        }
    };

    return (
        <div className="App">
            <h1>CV Maker</h1>
            <form onSubmit={handleSubmit}>
                <div className="cv-maker-container">
                    <textarea
                        className="form-textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Optionally enter a description of the wanted CV or target project"
                        rows="4"
                    />

                    <Select
                        isMulti
                        isLoading={isLoading}
                        options={users || []}
                        value={selectedUsers}
                        onChange={setSelectedUsers}
                        placeholder="Select consultants..."
                        className="react-select"
                    />

                    <button type="submit" className="create-cv-submit">
                        Generate CV
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CVMaker; 