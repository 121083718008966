import React from "react"
import { Link, useRouteContext, useNavigate, useMatchRoute } from "@tanstack/react-router"

const NavBar = () => {
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();
  const {
    auth: { user, setUser }
  } = useRouteContext({ from: "" })

  const isActive = (path) => {
    return matchRoute({ to: path });
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    setUser(null);
    navigate({ to: "/" });
  }

  if (!user) {
    return null; // Don't show NavBar if user is not logged in
  }

  return (
    <nav
      className="navbar App"
      style={{
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px"
        }}
      >
        <Link to="/search" className={isActive("/search") ? "active" : ""}>Search</Link>
        <Link to="/ai-search" className={isActive("/ai-search") ? "active" : ""}>AI Search</Link>
        <Link to="/cv-maker" className={isActive("/cv-maker") ? "active" : ""}>CV Maker</Link>
        <Link to="/faq" className={isActive("/faq") ? "active" : ""}>FAQ</Link>
        <Link to="/profile" className={isActive("/profile") ? "active" : ""}>Profile</Link>
      </div>

      <div className="navbar-brand"></div>
      <div className="navbar-menu">
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </nav>
  )
}

export default NavBar
